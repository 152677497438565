import React, { useState } from 'react';

import useFuzzySearch from '@core/hooks/useFuzzySearch';
import { useSearchStore } from '@core/store';
import classy from '@core/utils/classy';

import Flex from '@ui/Flex';
import Icon from '@ui/Icon';
import Toggle from '@ui/Toggle';

import classes from './style.module.scss';

const SearchProjectsFilter = () => {
  const [subdomainFilters, setSubdomainFilters, projectList] = useSearchStore(s => [
    s.subdomainFilters,
    s.setSubdomainFilters,
    s.projectList,
  ]);

  const toggleSubdomainFilter = (subdomain: string) => {
    if (subdomainFilters.includes(subdomain)) {
      setSubdomainFilters(subdomainFilters.filter(s => s !== subdomain));
    } else {
      setSubdomainFilters([...subdomainFilters, subdomain]);
    }
  };

  const [filter, setFilter] = useState('');
  const handleFilter = event => setFilter(event.target.value);
  const [filteredProjects] = useFuzzySearch(projectList, filter, { key: 'name', highlight: false });

  return (
    <aside className={classy(classes.SearchProjectsFilter, 'rm-SearchFilter')}>
      <header className={classy(classes['SearchProjectsFilter-header'], 'rm-SearchFilter-header')} tabIndex={-1}>
        {/* show project list search if there’s more than 6 project */}
        {projectList.length < 6 ? (
          'Filters'
        ) : (
          <Flex align="center" className={classes['SearchProjectsFilter-input-label']} gap="xs" tag="label">
            <Icon
              aria-label="Filter"
              className={classes['SearchProjectsFilter-input-label-icon']}
              name="filter"
              size="sm"
            />
            <input
              className={classy(classes['SearchProjectsFilter-input'], 'rm-SearchFilter-input')}
              onChange={handleFilter}
              placeholder="Filter"
              type="search"
            />
          </Flex>
        )}
      </header>
      <ol className={classes['SearchProjectsFilter-list']}>
        {filteredProjects.map(p => (
          <li key={p.name} className={classy(classes['SearchProjectsFilter-list-item'], 'rm-SearchFilter-list-item')}>
            <Toggle
              checked={subdomainFilters.includes(p.subdomain)}
              className={classes['SearchProjectsFilter-toggle']}
              label={p.name}
              onChange={() => {
                toggleSubdomainFilter(p.subdomain);
              }}
            />
          </li>
        ))}
      </ol>
      {filteredProjects.length === 0 && <div className={classes['SearchProjectsFilter-empty']}>No results found</div>}
    </aside>
  );
};

export default React.memo(SearchProjectsFilter);
