import type { SearchResults as SearchResultsType } from '@readme/api/src/mappings/search/types';
import type { ProjectClientSide } from '@readme/backend/models/project/types';

import React, { useCallback, useContext, useEffect, useRef } from 'react';
import FocusLock from 'react-focus-lock';

import type { IProjectMetadata } from '@Hub/Search/types';

import { ProjectContext, VersionContext } from '@core/context';
import useClassy from '@core/hooks/useClassy';
import { useMetricsAPIFetcher } from '@core/hooks/useMetricsAPI';
import useReadmeApi from '@core/hooks/useReadmeApi';
import { useSearchStore, useSuperHubStore } from '@core/store';

import SearchBox from './SearchBox';
import SearchProjectsFilter from './SearchProjectsFilter';
import SearchResults from './SearchResults';
import SearchTabs from './SearchTabs';
import styles from './style.module.scss';

interface Props {
  id: string;
  isModalOpen: boolean;
  projectsMeta: IProjectMetadata[];
  toggleModal: () => void;
}

const SuperHubSearch = ({ id, isModalOpen, projectsMeta, toggleModal }: Props) => {
  const bem = useClassy(styles, 'SuperHubSearch');
  const searchInput = useRef<HTMLInputElement | null>(null);

  const { project } = useContext(ProjectContext) as unknown as { project: ProjectClientSide };
  const { version } = useContext(VersionContext);

  const [
    prompt,
    updatePrompt,
    sectionFilter,
    buildDisplayedTabs,
    buildProjectList,
    setResults,
    subdomainFilters,
    projectList,
  ] = useSearchStore(store => [
    store.prompt,
    store.updatePrompt,
    store.sectionFilter,
    store.buildDisplayedTabs,
    store.buildProjectList,
    store.setResults,
    store.subdomainFilters,
    store.projectList,
  ]);

  const [apiBaseUrl, defaultApiOptions] = useSuperHubStore(store => [store.apiBaseUrl, store.defaultApiOptions]);
  const versionlessUrl = apiBaseUrl.split('/version')[0];
  const metricsFetch = useMetricsAPIFetcher({ version });

  const subdomainQueryString = subdomainFilters.map(subdomain => `&projects=${subdomain}`).join('');

  const {
    data: resultsData = {
      data: [],
      total: 0,
    },
    isLoading: isSearchLoading,
  } = useReadmeApi<SearchResultsType>(
    prompt
      ? `${versionlessUrl}/search?version=${version}&query=${prompt}${sectionFilter ? `&section=${sectionFilter}` : ''}${subdomainQueryString}`
      : null,
    {
      ...defaultApiOptions,
    },
  );

  useEffect(() => {
    buildProjectList(project);
  }, [buildProjectList, project]);

  // Build available modules for projects based on project metadata
  useEffect(() => {
    buildDisplayedTabs(projectsMeta);
  }, [buildDisplayedTabs, projectsMeta]);

  // Keep store in sync with results and loading states
  useEffect(() => {
    setResults(resultsData, isSearchLoading);
  }, [isSearchLoading, resultsData, setResults]);

  /**
   * On result selection, we want to track the search term and link clicked
   * in doc metrics and close the modal if a link was clicked
   */
  const handleResultSelection = useCallback(
    async ({ term, link }: { link?: string; term?: string }) => {
      const body = {
        search: term || searchInput?.current?.value,
        link: (link || '').split('#')[0],
        requestType: 'search',
      };

      try {
        await metricsFetch({
          path: 'create/search',
          method: 'POST',
          body,
        });
      } catch {
        // Fail silently
      }

      // If we have a link, it means we've clicked a result and should reset query + close modal
      if (link) {
        updatePrompt('');
        toggleModal();
      }
    },
    [metricsFetch, toggleModal, updatePrompt],
  );

  return (
    <div className={bem('&')} id={id} role="tabpanel" tabIndex={0}>
      <FocusLock
        autoFocus={false}
        className={bem('-container', projectList.length > 0 && '-container_withSidebar')}
        disabled={!isModalOpen}
      >
        <div className={bem('-col')}>
          <SearchBox
            inputRef={searchInput}
            isModalOpen={isModalOpen}
            onClickout={handleResultSelection}
            subdomain={project?.subdomain || ''}
          />
          <SearchTabs />
          <SearchResults
            onResultSelection={handleResultSelection}
            projectMetadata={projectsMeta}
            results={resultsData}
          />
        </div>
        {projectList.length > 0 && <SearchProjectsFilter />}
      </FocusLock>
    </div>
  );
};

export default SuperHubSearch;
